import React, { Component } from "react";

class LoadingBar extends Component {
  state = {};
  render() {
    return (
      <div className="w-full bg-gray-200 h-1 fixed top-0 left-0 right-0 animate-pulse">
        <div className="bg-orange-400 h-1 w-full"></div>
      </div>
    );
  }
}

export default LoadingBar;
