import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingBar from "./components/loading-bar";
import LoadingFull from "./components/loading-full";
import { routes } from "./pages/public/routes";
import { routes as routesAdmin } from "./pages/admin/routes";

const Login = React.lazy(() => import("./pages/admin/login"));
const Admin = React.lazy(() => import("./pages/admin/layouts/master"));
const Public = React.lazy(() => import("./pages/public/layouts/master"));

class App extends Component {
  state = {};
  render() {
    return (
      <div>
        <BrowserRouter>
          <Suspense fallback={<LoadingBar show={true} />}>
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/admin" element={<Admin />}>
                {routesAdmin.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.component />}
                  />
                ))}
              </Route>
              <Route path="/" element={<Public />}>
                {routes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.component />}
                  />
                ))}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
