import React from "react";
const Home = React.lazy(() => import("./home/home"));
const Shadaqah = React.lazy(() => import("./shadaqah"));
const ShadaqahThanks = React.lazy(() => import("./shadaqah/thanks"));
const Relawan = React.lazy(() => import("./relawan"));
const Qurban = React.lazy(() => import("./qurban"));
const QurbanThanks = React.lazy(() => import("./qurban/thanks"));

export const routes = [
  { path: "/", exact: true, name: "Homepage", component: Home },
  {
    path: "/shadaqah/thanks/:kd",
    exact: true,
    name: "ThankspageShadaqah",
    component: ShadaqahThanks,
  },
  { path: "/shadaqah", exact: true, name: "Shadaqah", component: Shadaqah },
  {
    path: "/qurban/thanks/:kd",
    exact: true,
    name: "Thankspage Qurban",
    component: QurbanThanks,
  },
  {
    path: "/qurban/:id",
    exact: true,
    name: "Qurban",
    component: Qurban,
  },
  {
    path: "/qurban",
    exact: true,
    name: "Qurban",
    component: Qurban,
  },
  {
    path: "/daftar-relawan/:id",
    exact: true,
    name: "Daftar Relawan",
    component: Relawan,
  },
  {
    path: "/daftar-relawan",
    exact: true,
    name: "Daftar Relawan",
    component: Relawan,
  },
];
